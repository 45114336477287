import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

import { authCodesMap } from '../authCodesMap';

function SignUp({ onSignInClick }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const signUp = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password);
    } catch (e) {
      const errorMessage = authCodesMap[e.code] || 'An error occurred creating your account. Please try again.';
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="SignUp">
      <div className="form-container">
        <div className="well auth-well">
          <p className="message">You must be signed in to view this form.</p>

          <h1>Get Started Today</h1>

          <form onSubmit={signUp}>
            <div className="input-container">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);

                  if (errorMessage) {
                    setErrorMessage('');
                  }
                }}
                placeholder="Enter email"
              />
            </div>

            <div className="input-container">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);

                  if (errorMessage) {
                    setErrorMessage('');
                  }
                }}
                placeholder="Enter password"
              />
            </div>

            {!errorMessage ? null :
              <p className="error-message">{errorMessage}</p>
            }

            <button
              type="submit"
            >
              Sign Up
            </button>
          </form>

          <div className="sign-in-container">
            <span>Already have an account? </span>

            <a onClick={() => onSignInClick()}>
              Sign In
            </a>
          </div>

          <div className="sign-up-terms-container">
            <p>
              <span>* By signing up, you agree to our </span>
              <a href="https://pupford.com/terms-conditions/" target="_blank">
                Terms of Use
              </a>
              <span> & </span>
              <a href="https://pupford.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </p>
          </div>

          <div className="copyright-container">
            <p>Copyright © {new Date().getFullYear()}, Pupford LLC - All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
