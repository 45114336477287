import { useEffect, useState } from 'react';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import './FormFileUpload.scss';
import { formatTemplateText } from '../../../../utils/formatTemplateText';
import StyledDropzone from './StyledDropzone';
import timesIcon from '../../../../images/times.svg';
import trashIcon from '../../../../images/trash.svg';
import loadingImage from '../../../../images/loading.svg';

function FormFileUpload({
  helperText,
  internalId,
  label,
  required,
  fontColor,
  maxFiles,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [deletingFileIndex, setDeletingFileIndex] = useState(-1);

  useEffect(() => {
    if (filesToUpload.length) {
      if (!uploading) {
        uploadFiles(filesToUpload);
      }

      setFilesToUpload([]);
    }
  }, [filesToUpload]);

  const handleFiles = (files) => {
    setFilesToUpload(files);
  };

  const uploadFiles = async (files) => {
    if ((answers[internalId].value.length + files.length) > maxFiles) {
      setErrorMessage(`The maximum number of files allowed is ${maxFiles}.`);
      return;
    }

    setErrorMessage('');
    setUploading(true);

    try {
      const storage = getStorage();
      const answerData = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const currentMilliseconds = Date.now();
        const storagePath = `form-uploads/${currentMilliseconds}-${file.name}`;
        const storageRef = ref(storage, storagePath);

        await uploadBytes(storageRef, file);

        const url = await getDownloadURL(storageRef);

        answerData.push({
          url,
          storagePath,
          name: file.name,
          type: file.type,
          size: file.size,
          date: currentMilliseconds,
        });
      }

      onAnswerChange(internalId, [
        ...answers[internalId].value,
        ...answerData,
      ]);
    } catch (e) {
      console.log('error', e);
      setErrorMessage('There was an error adding your files. Please try again.');
    }

    setUploading(false);
  };

  const deleteFile = async (i, storagePath) => {
    setErrorMessage('');
    setDeletingFileIndex(i);

    try {
      const storage = getStorage();
      const fileRef = ref(storage, storagePath);

      await deleteObject(fileRef);

      const updatedFiles = [ ...answers[internalId].value ];
      updatedFiles.splice(i, 1);

      onAnswerChange(internalId, updatedFiles);
    } catch (e) {
      console.log('deleteFile error:', e);
      setErrorMessage('There was an error deleting this file. Please try again.');
    }

    setDeletingFileIndex(-1);
  };

  const formatFileName = (fileName) => {
    const fileArr = fileName.split('.');
    const nameArr = fileArr[0].split('');

    if (nameArr.length > 25) {
      nameArr.splice(18, (nameArr.length) - 21, '...')
    }

    return nameArr.join('') + `.${(fileArr[1] || '')}`;
  };

  return (
    <fieldset className={`input-container FormFileUpload${hasError ? ' error' : ''}`}>
      {!label ? null :
        <legend
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </legend>
      }
      <div className="input-spacing-container">
        <StyledDropzone
          onFilesAdded={handleFiles}
          singleFile={maxFiles === 1}
          uploading={uploading}
          disabled={disabled || false}
        />

        {!errorMessage ? null :
          <div className="file-row error-message-container">
            <p>{errorMessage}</p>
            <button
              onClick={() => setErrorMessage('')}
            >
              <img src={timesIcon} alt="close" />
            </button>
          </div>
        }

        {(answers[internalId].value || []).map((answer, i) => {
          return (
            <div key={`${internalId}-answer-${i}`} className="file-row">
              <p>{formatFileName(answer.name)}</p>

              <div>
                {deletingFileIndex === i ?
                  <div className="deleting-indicator-container">
                    <img src={loadingImage} alt="deleting" />
                  </div> :
                  <button
                    className="secondary delete-button"
                    onClick={() => deleteFile(i, answer.storagePath)}
                    disabled={disabled || false}
                  >
                    <img src={trashIcon} alt="remove" />
                  </button>
                }
              </div>
            </div>
          );
        })}
      </div>
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </fieldset>
  );
}

export default FormFileUpload;
