import './FormNotFound.scss';

function FormNotFound() {
  return (
    <div className="FormNotFound">
      <div className="inner">
        <h1>Oops!</h1>
        <p>It looks like this form doesn't exist. Please check the url or contact us if you have any troubles finding what you're looking for.</p>
      </div>
    </div>
  );
}

export default FormNotFound;
