export const formatTemplateText = (text, variables) => {
  const leftBracketsSplitArray = text.split('{{');
  const indicesWithBrackets = [];

  leftBracketsSplitArray.forEach((s, i) => {
    if (s.includes('}}')) {
      indicesWithBrackets.push(i);
    }
  });

  indicesWithBrackets.forEach(index => {
    const text = leftBracketsSplitArray[index];
    const templateArr = text.split('}}');
    const templateContent = templateArr[0].replace(/\s/g, '');
    const templateContentArr = templateContent.split('|');

    let updatedValue = '';

    for (let i = 0; i < templateContentArr.length; i++) {
      const templateItem = templateContentArr[i];

      if (variables[templateItem]) {
        updatedValue += variables[templateItem];
        break;
      }

      if (templateItem.includes('default')) {
        const defaultString = templateItem.replace('default:', '');
        updatedValue += defaultString.replace(/'/g, '');;
        break;
      }
    }

    if (templateArr[1]) {
      updatedValue += templateArr[1];
    }

    leftBracketsSplitArray[index] = updatedValue;
  });

  return leftBracketsSplitArray.join('');
};
