import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, addDoc, collection, updateDoc } from 'firebase/firestore';
import axios from 'axios';

import { config } from '../../config';
import './Form.scss';
import { checkCondition } from '../../utils/checkCondition';
import { validateAnswer } from '../../utils/validateAnswer';
import FormContent from './FormContent';

function Form({ form, userId }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState({});
  const [answersInitialized, setAnswersInitialized] = useState(false);
  const [variables, setVariables] = useState({});
  const [submissionId, setSubmissionId] = useState('');
  const [fieldsToHide, setFieldsToHide] = useState([]);
  const [pagesToHide, setPagesToHide] = useState([]);
  const [fieldsToRequire, setFieldsToRequire] = useState([]);
  const [fieldsToDisable, setFieldsToDisable] = useState([]);
  const [fieldsWithErrors, setFieldsWithErrors] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showFormSubmittedMessage, setShowFormSubmittedMessage] = useState(false);
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false);

  useEffect(() => {
    if (form && form.id && !answersInitialized) {
      initializeFormData();
    }
  }, [form, answersInitialized]);

  useEffect(() => {
    checkRules();
  }, [answers]);

  const initializeFormData = async () => {
    let pageIndex = 0;

    if (form.content.length) {
      const db = getFirestore();
      let submissionId = localStorage.getItem(form.id);
      let submission;

      if (submissionId) {
        try {
          const submissionRef = await getDoc(doc(db, 'form-submissions', submissionId));
          submission = submissionRef.data();
        } catch (e) {
          console.log('error', e);
        }
      }

      const initialAnswers = {};
      const initialVariables = {};

      form.content.forEach(p => {
        p.questions.forEach(q => {
          if (q.type !== 'heading' && q.type !== 'paragraph') {
            initialAnswers[q.internalId] = {
              value: q.type === 'file-upload' ? [] : '',
              variableName: q.saveAnswerAsVariable ? q.variableName : '',
              required: q.required,
              type: q.type === 'text-input' && q.inputType === 'number' ? 'number' : q.type,
            };

            if (q.saveAnswerAsVariable) {
              initialVariables[q.variableName] = '';
            }
          }
        });
      });

      if (submission && submission.answers) {
        pageIndex = submission.currentPage || 0;

        for (let key in submission.answers) {
          const answer = submission.answers[key];

          if (answer.value) {
            initialAnswers[key].value = answer.value;

            if (answer.variableName) {
              initialVariables[answer.variableName] = answer.value;
            }
          }
        }
      } else {
        try {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const userIdFromParams = urlParams.get('userId');

          const submissionRef = await addDoc(collection(db, 'form-submissions'), {
            answers: initialAnswers,
            formId: form.id,
            userId: userId || userIdFromParams || '',
            created: Date.now(),
            updated: Date.now(),
            currentPage: currentPage,
            fieldsToHide,
            pagesToHide,
            fieldsToRequire,
            fieldsToDisable,
            submitted: null,
            deleted: null,
          });

          submissionId = submissionRef.id;
          localStorage.setItem(form.id, submissionId);
        } catch (e) {
          console.log('addDoc error', e);
        }
      }

      setCurrentPage(pageIndex);
      setAnswers(initialAnswers);
      setVariables(initialVariables);
      setSubmissionId(submissionId);
      setAnswersInitialized(true);
    }
  };

  const handlePageChange = (page) => {
    if (page < currentPage) {
      setFieldsWithErrors([]);
      setCurrentPage(page);
      return;
    }

    const updatedFieldsWithErrors = [];

    form.content[currentPage].questions.forEach(q => {
      if (!answers[q.internalId]) {
        return;
      }

      const shouldAlwaysRequire = fieldsToRequire.includes(q.internalId);
      const answer = { ...answers[q.internalId] };

      if (shouldAlwaysRequire) {
        answer.required = true;
      }

      if (!fieldsToHide.includes(q.internalId) && !fieldsToDisable.includes(q.internalId) && !validateAnswer(answer)) {
        updatedFieldsWithErrors.push(q.internalId);
      }
    });

    if (updatedFieldsWithErrors.length) {
      setFieldsWithErrors(updatedFieldsWithErrors);
      return;
    }

    setFieldsWithErrors([]);
    setCurrentPage(page);
    updateSubmissionAnswers(answers, page);
  };

  const checkRules = () => {
    const updatedFieldsToHide = [];
    const updatedPagesToHide = [];
    const updatedFieldsToRequire = [];
    const updatedFieldsToDisable = [];

    (form.conditions || []).forEach((condition) => {
      let meetsAllConditions = true;
      let meetsAtLeastOneCondition = false;

      condition.conditionsToMeet.forEach(c => {
        if (!answers[c.field]) {
          return;
        }

        const valueOfFieldToCheck = answers[c.field].value;
        const valueToCheckAgainst = c.target === 'value' ? c.value : answers[c.targetField].value;
        const fieldType = answers[c.field].type;
        const meetsCondition = checkCondition(c, valueOfFieldToCheck, valueToCheckAgainst, fieldType);

        if (meetsCondition) {
          meetsAtLeastOneCondition = true;
        } else {
          meetsAllConditions = false;
        }
      });

      if ((condition.meetsAllConditions && meetsAllConditions) || (!condition.meetsAllConditions && meetsAtLeastOneCondition)) {
        if (condition.type === 'hide-field') {
          condition.applyTo.forEach(f => {
            updatedFieldsToHide.push(f);
          });
        } else if (condition.type === 'hide-page') {
          condition.applyTo.forEach(p => {
            const pageIndex = form.content.findIndex(page => page.id === p);

            if (pageIndex !== -1) {
              updatedPagesToHide.push(pageIndex);
            }
          });
        } else if (condition.type === 'require-field') {
          condition.applyTo.forEach(f => {
            updatedFieldsToRequire.push(f);
          });
        } else if (condition.type === 'disable-field') {
          condition.applyTo.forEach(f => {
            updatedFieldsToDisable.push(f);
          });
        }
      }
    });

    setFieldsToHide(updatedFieldsToHide);
    setPagesToHide(updatedPagesToHide);
    setFieldsToRequire(updatedFieldsToRequire);
    setFieldsToDisable(updatedFieldsToDisable);
  };

  const handleAnswerChange = (internalId, value) => {
    const updatedAnswers = { ...answers };

    updatedAnswers[internalId] = {
      ...updatedAnswers[internalId],
      value,
    };

    if (updatedAnswers[internalId].variableName) {
      const updatedVariables = {
        ...variables,
      };

      updatedVariables[updatedAnswers[internalId].variableName] = value;
      setVariables(updatedVariables);
    }

    setAnswers(updatedAnswers);

    if (Array.isArray(value)) {
      updateSubmissionAnswers(updatedAnswers);
    }

    if (fieldsWithErrors.length) {
      const errorIndex = fieldsWithErrors.indexOf(internalId);

      if (errorIndex !== -1) {
        const updatedFieldsWithErrors = [ ...fieldsWithErrors ];
        updatedFieldsWithErrors.splice(errorIndex, 1);

        setFieldsWithErrors(updatedFieldsWithErrors);
      }
    }
  };

  const updateSubmissionAnswers = async (answers, page) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'form-submissions', submissionId), {
        answers,
        updated: Date.now(),
        currentPage: page !== undefined ? page : currentPage,
        fieldsToHide,
        pagesToHide,
        fieldsToRequire,
        fieldsToDisable,
      });
    } catch (e) {
      console.log('updateSubmissionAnswers error:', e);
    }
  };

  const submitForm = async () => {
    setShowFormSubmissionError(false);

    const updatedFieldsWithErrors = [];

    form.content[currentPage].questions.forEach(q => {
      if (!answers[q.internalId]) {
        return;
      }

      const shouldAlwaysRequire = fieldsToRequire.includes(q.internalId);
      const answer = { ...answers[q.internalId] };

      if (shouldAlwaysRequire) {
        answer.required = true;
      }

      if (!fieldsToHide.includes(q.internalId) && !fieldsToDisable.includes(q.internalId) && !validateAnswer(answer)) {
        updatedFieldsWithErrors.push(q.internalId);
      }
    });

    if (updatedFieldsWithErrors.length) {
      setFieldsWithErrors(updatedFieldsWithErrors);
      return;
    }

    setSubmittingForm(true);

    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'form-submissions', submissionId), {
        answers,
        updated: Date.now(),
        submitted: Date.now(),
        fieldsToHide,
        pagesToHide,
        fieldsToRequire,
        fieldsToDisable,
      });

      localStorage.removeItem(form.id);

      handleFormActions(form.actions || []);
    } catch (e) {
      setShowFormSubmissionError(true);
      console.log('submitForm error:', e);
    }

    setSubmittingForm(false);
  };

  const handleFormActions = async (actions) => {
    let redirectUrl = '';

    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];

      if (action.type === 'add-to-klaviyo') {
        try {
          const emailField = answers[action.emailField];
          const email = (emailField && emailField.value) ? emailField.value : '';
          const userData = {
            email,
          };

          action.additionalFields.forEach(field => {
            const formField = answers[field.formField];
            const value = (formField && formField.value) ? formField.value : '';
            userData[field.customKlaviyoField] = value;
          });

          await axios.post(`${config.apiGateway}/email-service/v1/add-user-to-klaviyo-list`, {
            listId: action.listId,
            userData,
          });
        } catch (e) {
          console.log('error', e);
          // continue without adding to Klaviyo
        }
      }

      if (action.type === 'add-to-sendlane') {
        try {
          const emailField = answers[action.emailField];
          const email = (emailField && emailField.value) ? emailField.value : '';
          const customFields = [];

          action.additionalFields.forEach(field => {
            const formField = answers[field.formField];
            const value = (formField && formField.value) ? formField.value : '';
            customFields.push({
              id: field.customSendlaneFieldId,
              value,
            });
          });

          await axios.post(`${config.apiGateway}/email-service/v1/add-user-to-sendlane-list`, {
            listId: action.listId,
            email,
            customFields,
          });
        } catch (e) {
          console.log('error', e);
          // continue without adding to Sendlane
        }
      }

      if (action.type === 'redirect-to-page') {
        redirectUrl = action.pageURL;
      }
    }

    if (redirectUrl) {
      window.top.location.href = redirectUrl;
    } else {
      setShowFormSubmittedMessage(true);
    }
  };

  if (!answersInitialized) {
    return null;
  }

  return (
    <div className="Form">
      {showFormSubmittedMessage ?
        <div className="form-submitted">
          <div className="inner">
            <h1>Thank You!</h1>
            <p>Your response has been recorded.</p>
          </div>
        </div> :
        <>
          {!form.content[currentPage] ? null :
            <>
              <div className={form.content[currentPage].layout === 'center' ? 'full' : 'split'}>
                <div
                  className={`full-container${form.content[currentPage].disablePadding ? ' disable-padding' : ''}`}
                  style={{
                    backgroundColor: form.content[currentPage].backgroundColor || '#fff',
                    backgroundImage: `url(${form.content[currentPage].backgroundImage})` || '',
                  }}
                >
                  <div
                    className="form-container"
                    style={{
                      backgroundColor: form.content[currentPage].formBackgroundColor || '#fff',
                      backgroundImage: `url(${form.content[currentPage].formBackgroundImage})` || '',
                      boxShadow: form.content[currentPage].boxShadowEnabled ? '0 4px 4px rgba(87, 100, 126, 0.2)' : '',
                    }}
                  >
                    <FormContent
                      page={form.content[currentPage]}
                      currentPage={currentPage}
                      numberOfPages={form.content.length}
                      pagesToHide={pagesToHide}
                      onPageChange={handlePageChange}
                      answers={answers}
                      onAnswerChange={handleAnswerChange}
                      variables={variables}
                      fieldsToHide={fieldsToHide}
                      fieldsToRequire={fieldsToRequire}
                      fieldsToDisable={fieldsToDisable}
                      fieldsWithErrors={fieldsWithErrors}
                      submittingForm={submittingForm}
                      showFormSubmissionError={showFormSubmissionError}
                      submitForm={submitForm}
                    />
                  </div>
                </div>
                <div
                  className="split-container"
                  style={{
                    flexDirection: form.content[currentPage].layout === 'left' ? 'row' : 'row-reverse',
                  }}
                >
                  <div
                    className="form-container"
                    style={{
                      backgroundColor: form.content[currentPage].formBackgroundColor || '#fff',
                    }}
                  >
                    <div
                      className="form-inner"
                      style={{
                        backgroundImage: `url(${form.content[currentPage].formBackgroundImage})` || '',
                      }}
                    >
                      <FormContent
                        page={form.content[currentPage]}
                        currentPage={currentPage}
                        numberOfPages={form.content.length}
                        pagesToHide={pagesToHide}
                        onPageChange={handlePageChange}
                        answers={answers}
                        onAnswerChange={handleAnswerChange}
                        variables={variables}
                        fieldsToHide={fieldsToHide}
                        fieldsToRequire={fieldsToRequire}
                        fieldsToDisable={fieldsToDisable}
                        fieldsWithErrors={fieldsWithErrors}
                        submittingForm={submittingForm}
                        showFormSubmissionError={showFormSubmissionError}
                        submitForm={submitForm}
                      />
                    </div>
                  </div>
                  <div
                    className="side-container"
                  >
                    <div
                      className="side-container-inner"
                      style={{
                        backgroundImage: `url(${form.content[currentPage].backgroundImage})` || '',
                        backgroundColor: form.content[currentPage].backgroundColor || '#fff',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
      }
    </div>
  );
}

export default Form;
