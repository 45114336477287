import React from 'react';

import './Loading.scss';
import LoadingImage from '../../images/loading.svg';

function Loading(props) {
  return (
    <div className="Loading">
      <div className="loading-inner">
        <img src={LoadingImage} alt="Loading..." />
        <p>{props.message}</p>
      </div>
    </div>
  );
}

export default Loading;
