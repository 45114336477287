import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormSelectDropdown({
  helperText,
  internalId,
  label,
  required,
  inputBackground,
  fontColor,
  options,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  return (
    <div className="input-container">
      {!label ? null :
        <label
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </label>
      }
      <select
        id={internalId}
        required={required}
        style={{ backgroundColor: inputBackground, color: fontColor }}
        value={answers[internalId].value}
        onChange={(e) => onAnswerChange(internalId, e.target.value)}
        disabled={disabled || false}
        className={hasError ? 'error' : ''}
      >
        <option value="" disabled>Select an option...</option>
        {options.map((o, i) => {
          return <option key={`${internalId}-option-${i}`} value={o}>{o}</option>;
        })}
      </select>
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </div>
  );
}

export default FormSelectDropdown;
