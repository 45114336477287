import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormTextInput({
  helperText,
  inputType,
  internalId,
  label,
  placeholder,
  required,
  inputBackground,
  fontColor,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  return (
    <div className="input-container">
      {!label ? null :
        <label
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </label>
      }
      <input
        type={inputType}
        id={internalId}
        placeholder={formatTemplateText(placeholder)}
        required={required}
        style={{ backgroundColor: inputBackground, color: fontColor }}
        value={answers[internalId].value}
        onChange={(e) => onAnswerChange(internalId, e.target.value)}
        disabled={disabled || false}
        className={hasError ? 'error' : ''}
      />
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </div>
  );
}

export default FormTextInput;
