import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormHeading({ text, fontSize, fontWeight, fontColor, variables }) {
  return (
    <h2 style={{ fontSize, fontWeight, color: fontColor }}>
      {formatTemplateText(text, variables)}
    </h2>
  );
}

export default FormHeading;
