import { useEffect, useState } from 'react';

import './FormContent.scss';
import FormDateInput from './FormDateInput';
import FormFileUpload from './FormFileUpload';
import FormHeading from './FormHeading';
import FormMultiSelect from './FormMultiSelect';
import FormParagraph from './FormParagraph';
import FormRating from './FormRating';
import FormSelectDropdown from './FormSelectDropdown';
import FormSingleSelect from './FormSingleSelect';
import FormTextInput from './FormTextInput';

function FormContent({
  page,
  currentPage,
  numberOfPages,
  pagesToHide,
  onPageChange,
  answers,
  onAnswerChange,
  variables,
  fieldsToHide,
  fieldsToRequire,
  fieldsToDisable,
  fieldsWithErrors,
  submittingForm,
  showFormSubmissionError,
  submitForm,
}) {
  const [previousPage, setPreviousPage] = useState(-1);
  const [nextPage, setNextPage] = useState(-1);

  useEffect(() => {
    const availablePages = [];

    for (let i = 0; i < numberOfPages; i++) {
      if (!pagesToHide.includes(i)) {
        availablePages.push(i);
      }
    }

    const indexOfCurrentPage = availablePages.indexOf(currentPage);
    const previous = availablePages[indexOfCurrentPage - 1] !== undefined ?
      availablePages[indexOfCurrentPage - 1] :  -1;
    const next = availablePages[indexOfCurrentPage + 1] !== undefined ?
      availablePages[indexOfCurrentPage + 1] : -1;

    setPreviousPage(previous);
    setNextPage(next);
  }, [currentPage, pagesToHide, numberOfPages]);

  const renderQuestion = (question) => {
    const q = { ...question };

    if (fieldsToHide.includes(q.internalId)) {
      return null;
    }

    if (fieldsToRequire.includes(q.internalId)) {
      q.required = true;
    }

    if (fieldsToDisable.includes(q.internalId)) {
      q.disabled = true;
    }

    if (fieldsWithErrors.includes(q.internalId)) {
      q.hasError = true;
    }

    switch (q.type) {
      case 'heading':
        return (
          <FormHeading
            {...q}
            fontColor={page.fontColor}
            variables={variables}
          />
        );
      case 'paragraph':
        return (
          <FormParagraph
            {...q}
            fontColor={page.fontColor}
            variables={variables}
          />
        );
      case 'text-input':
        return (
          <FormTextInput
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'date':
        return (
          <FormDateInput
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'select-dropdown':
        return (
          <FormSelectDropdown
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'multi-select':
        return (
          <FormMultiSelect
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'single-select':
        return (
          <FormSingleSelect
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'rating':
        return (
          <FormRating
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      case 'file-upload':
        return (
          <FormFileUpload
            {...q}
            inputBackground={page.inputBackground}
            fontColor={page.fontColor}
            answers={answers}
            onAnswerChange={onAnswerChange}
            variables={variables}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="FormContent">
      {page.questions.map(q => {
        return (
          <div key={q.internalId}>
            {renderQuestion(q)}
          </div>
        );
      })}

      {!showFormSubmissionError ? null :
        <div className="error-message-container">
          <p>
            There was an error submitting your request. Please try again.
          </p>
        </div>
      }

      {!fieldsWithErrors.length ? null :
        <div className="error-message-container">
          <p>
            There are errors on this page. Please fix them before continuing.
          </p>
        </div>
      }

      <div className="action-buttons-row">
        {previousPage === -1 ?
          (numberOfPages === 1 ? null : <div></div>) :
          <button
            style={{color: page.fontColor, borderColor: page.fontColor}}
            onClick={() => onPageChange(previousPage)}
            disabled={submittingForm}
          >
            Back
          </button>
        }

        {nextPage === -1 ?
          <button
            style={{color: page.fontColor, borderColor: page.fontColor}}
            onClick={submitForm}
            disabled={submittingForm}
          >
            {submittingForm ? 'Submitting...' : 'Submit'}
          </button> :
          <button
            style={{color: page.fontColor, borderColor: page.fontColor}}
            onClick={() => onPageChange(nextPage)}
          >
            Next
          </button>
        }
      </div>
    </div>
  );
}

export default FormContent;
