import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormParagraph({ text, fontSize, fontWeight, fontColor, variables }) {
  return (
    <p
      style={{ fontSize, fontWeight, whiteSpace: 'pre-line', lineHeight: 1.7, color: fontColor }}
    >
      {formatTemplateText(text, variables)}
    </p>
  );
}

export default FormParagraph;
