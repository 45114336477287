const checkArrayState = (state, valueOfFieldToCheck, valueToCheckAgainst) => {
  switch (state) {
    case 'is-empty':
      return valueOfFieldToCheck.length === 0;
    case 'is-not-empty':
      return valueOfFieldToCheck.length !== 0;
    case 'includes':
      return valueOfFieldToCheck.includes(valueToCheckAgainst);
    case 'does-not-include':
      return !valueOfFieldToCheck.includes(valueToCheckAgainst);
    default:
      return false;
  }
};

const checkValueState = (state, valueOfFieldToCheck, valueToCheckAgainst) => {
  switch (state) {
    case 'is-empty':
      return valueOfFieldToCheck.length === 0;
    case 'is-not-empty':
      return valueOfFieldToCheck.length !== 0;
    case 'is-greater-than-or-equal-to':
      return valueOfFieldToCheck >= valueToCheckAgainst;
    case 'is-less-than-or-equal-to':
      return valueOfFieldToCheck <= valueToCheckAgainst;
    case 'is-greater-than':
      return valueOfFieldToCheck > valueToCheckAgainst;
    case 'is-less-than':
      return valueOfFieldToCheck < valueToCheckAgainst;
    case 'is-equal-to':
      return valueOfFieldToCheck === valueToCheckAgainst;
    case 'is-not-equal-to':
      return valueOfFieldToCheck !== valueToCheckAgainst;
    case 'contains':
      return valueOfFieldToCheck.includes(valueToCheckAgainst);
    case 'does-not-contain':
      return !valueOfFieldToCheck.includes(valueToCheckAgainst);
    case 'starts-with':
      return valueOfFieldToCheck.startsWith(valueToCheckAgainst);
    case 'does-not-start-with':
      return !valueOfFieldToCheck.startsWith(valueToCheckAgainst);
    case 'ends-with':
      return valueOfFieldToCheck.endsWith(valueToCheckAgainst);
    case 'does-not-end-with':
      return !valueOfFieldToCheck.endsWith(valueToCheckAgainst);
    default:
      return false;
  }
};

export const checkCondition = (condition, valueOfFieldToCheck, valueToCheckAgainst, fieldType) => {
  if (fieldType === 'multi-select') {
    valueOfFieldToCheck = valueOfFieldToCheck === '' ? [] : valueOfFieldToCheck.split(',');
  }

  if (Array.isArray(valueOfFieldToCheck)) {
    return checkArrayState(condition.state, valueOfFieldToCheck, valueToCheckAgainst);
  } else {
    if (fieldType === 'number' || fieldType === 'rating') {
      valueOfFieldToCheck = +valueOfFieldToCheck;
      valueToCheckAgainst = +valueToCheckAgainst;
    } else if (typeof valueOfFieldToCheck === 'string') {
      valueOfFieldToCheck = valueOfFieldToCheck.toLowerCase();
      valueToCheckAgainst = valueToCheckAgainst.toLowerCase();
    }

    return checkValueState(condition.state, valueOfFieldToCheck, valueToCheckAgainst);
  }
};
