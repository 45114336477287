import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import { authCodesMap } from '../authCodesMap';

function LogIn({
  onSignUpClick,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const signIn = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      const errorMessage = authCodesMap[e.code] || 'An error occurred signing you in. Please try again.';
      setErrorMessage(errorMessage);
    }
  };

  return (
    <div className="LogIn">
      <div className="form-container">
        <div className="well auth-well">
          <p className="message">You must be signed in to view this form.</p>

          <h1>Sign In</h1>

          <form onSubmit={signIn}>
            <div className="input-container">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);

                  if (errorMessage) {
                    setErrorMessage('');
                  }
                }}
                placeholder="Enter email"
              />
            </div>

            <div className="input-container">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);

                  if (errorMessage) {
                    setErrorMessage('');
                  }
                }}
                placeholder="Enter password"
              />
            </div>

            {!errorMessage ? null :
              <p className="error-message">{errorMessage}</p>
            }

            <button
              type="submit"
            >
              Sign In
            </button>
          </form>

          <div className="forgot-password-link-container">
            <a href="https://pupford.com/forgot-password/" target="_blank">
              Forgot Password
            </a>
          </div>

          <div className="or-container">
            <div className="or-divider"></div>
            <p>[OR]</p>
            <div className="or-divider"></div>
          </div>

          <div className="create-account-button-container">
            <a>
              <button className="alternate" onClick={() => onSignUpClick()}>
                Create Account
              </button>
            </a>
          </div>

          <div className="terms-container">
            <div className="terms-container-inner">
              <a href="https://pupford.com/terms-conditions/" target="_blank">
                Terms of Use
              </a>
              <p className="terms-divider">|</p>
              <a href="https://pupford.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>

          <div className="copyright-container">
            <p>Copyright © {new Date().getFullYear()}, Pupford LLC - All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
