import { useState, useEffect } from 'react';
import { getFirestore, getDocs, query, collection, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import FormNotFound from './FormNotFound';
import Loading from './Loading';
import Form from './Form';
import Auth from './Auth';

function Main() {
  const [path, setPath] = useState('');
  const [form, setForm] = useState({
    content: [],
  });
  const [loading, setLoading] = useState(false);
  const [showFormNotFound, setShowFormNotFound] = useState(false);
  const [requireAuth, setRequireAuth] = useState(false);
  const [user, setUser] = useState({});
  const [userInitialized, setUserInitialized] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserInitialized(true);

      if (user) {
        setUser(user);
      } else {
        setUser({});
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (path && !form.id) {
      fetchForm(path);
    }

    const pathArr = window.location.pathname.split('/');

    if (pathArr[1]) {
      setPath(pathArr[1]);
    } else {
      setShowFormNotFound(true);
    }
  }, [path, form]);

  const fetchForm = async (path) => {
    try {
      const db = getFirestore();
      const q = query(collection(db, 'forms'), where('path', '==', path));
      const querySnapshot = await getDocs(q);
      const doc = querySnapshot.docs[0];
      const formData = doc ? doc.data() : null;

      if (formData && formData.live) {
        setForm({
          ...formData,
          content: JSON.parse(formData.content),
          id: doc.id,
        });

        if (formData.requireAuth) {
          setRequireAuth(true);
        }
      } else {
        setShowFormNotFound(true);
      }
    } catch (e) {
      setShowFormNotFound(true);
    }

    setLoading(false);
  };

  if (loading || !userInitialized) {
    return <Loading message="Loading form..."/>;
  }

  if (showFormNotFound) {
    return <FormNotFound/>;
  }

  if (requireAuth && !user.uid) {
    return <Auth/>;
  }

  return (
    <div className="Main">
      <Form
        form={form}
        userId={user.uid}
      />
    </div>
  );
}

export default Main;
