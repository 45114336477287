import { useEffect, useState } from 'react';
import moment from 'moment';

import './FormDateInput.scss';
import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormDateInput({
  helperText,
  internalId,
  label,
  required,
  inputBackground,
  fontColor,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');

  useEffect(() => {
    const date = moment(answers[internalId].value);
    
    if (date.isValid()) {
      const momentMonth = (date.month() + 1).toString();

      if (momentMonth !== month) {
        setMonth(momentMonth);
      }

      const momentDay = (date.date()).toString();

      if (momentDay !== day) {
        setDay(momentDay);
      }

      const momentYear = (date.year()).toString();

      if (momentYear !== year) {
        setYear(momentYear);
      }
    }
  }, []);

  useEffect(() => {
    const date = moment(`${month}/${day}/${year}`);

    if (date.isValid()) {
      onAnswerChange(internalId, date.valueOf());
    } else {
      onAnswerChange(internalId, '');
    }
  }, [month, day, year]);

  return (
    <div className="input-container FormDateInput">
      {!label ? null :
        <label
          style={{ color: fontColor }}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </label>
      }
      <div className="date-inputs-container">
        <input
          type="text"
          required={required}
          placeholder="MM"
          style={{ backgroundColor: inputBackground, color: fontColor }}
          value={month}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/gi, '');

            if (value.length > 2) {
              return;
            }

            setMonth(value);
          }}
          disabled={disabled || false}
          className={`month${hasError ? ' error' : ''}`}
        />
        <div
          className="date-divider"
          style={{ backgroundColor: inputBackground, borderColor: hasError ? '#dc3545' : '#592E2C' }}
        >/</div>
        <input
          type="text"
          required={required}
          placeholder="DD"
          style={{ backgroundColor: inputBackground, color: fontColor }}
          value={day}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/gi, '');

            if (value.length > 2) {
              return;
            }

            setDay(value);
          }}
          disabled={disabled || false}
          className={`day${hasError ? ' error' : ''}`}
        />
        <div
          className="date-divider"
          style={{ backgroundColor: inputBackground, borderColor: hasError ? '#dc3545' : '#592E2C' }}
        >/</div>
        <input
          type="text"
          required={required}
          placeholder="YYYY"
          style={{ backgroundColor: inputBackground, color: fontColor }}
          value={year}
          onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/gi, '');

            if (value.length > 4) {
              return;
            }

            setYear(value);
          }}
          disabled={disabled || false}
          className={`year${hasError ? ' error' : ''}`}
        />
      </div>
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </div>
  );
}

export default FormDateInput;
