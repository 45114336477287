import { useState } from 'react';

import './Auth.scss';
import LogIn from './LogIn';
import SignUp from './SignUp';

function Auth({}) {
  const [showSignUp, setShowSignUp] = useState(false);

  return (
    <div className="Auth">
      <div className="auth-inner">
        {showSignUp ?
          <SignUp
            onSignInClick={() => setShowSignUp(false)}
          /> :
          <LogIn
            onSignUpClick={() => setShowSignUp(true)}
          />
        }
      </div>
    </div>
  );
}

export default Auth;
