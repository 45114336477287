import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormSingleSelect({
  helperText,
  internalId,
  label,
  required,
  fontColor,
  options,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  return (
    <fieldset className={`input-container${hasError ? ' error' : ''}`}>
      {!label ? null :
        <legend
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </legend>
      }
      {options.map((o, i) => {
        return (
          <div key={`${internalId}-option-${i}`} className="checkbox-row">
            <input
              type="radio"
              id={o}
              value={o}
              checked={answers[internalId].value === o}
              onChange={(e) => {
                onAnswerChange(internalId, e.target.value);
              }}
              disabled={disabled || false}
            />
            <label
              style={{ color: fontColor }}
              htmlFor={o}
            >
              {o}
            </label>
          </div>
        );
      })}
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </fieldset>
  );
}

export default FormSingleSelect;
