import Rating from 'react-rating';

import { formatTemplateText } from '../../../../utils/formatTemplateText';
import emptyStarIcon from '../../../../images/star-empty.svg';
import fullStarIcon from '../../../../images/star-full.svg';

function FormRating({
  helperText,
  internalId,
  label,
  required,
  fontColor,
  answers,
  onAnswerChange,
  variables,
  outOf,
  disabled,
  hasError,
  iconType,
}) {
  return (
    <fieldset className={`input-container${hasError ? ' error' : ''}`}>
      {!label ? null :
        <legend
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </legend>
      }
      <div className="input-spacing-container">
        <Rating
          initialRating={+answers[internalId].value || 0}
          stop={outOf}
          onChange={(value) => onAnswerChange(internalId, value)}
          emptySymbol={<img height={32} src={emptyStarIcon} alt="rating" />}
          fullSymbol={<img height={32} src={fullStarIcon} alt="rating" />}
          style={{ color: fontColor }}
          readonly={disabled || false}
        />
      </div>
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </fieldset>
  );
}

export default FormRating;
