import { useEffect, useState } from 'react';

import { formatTemplateText } from '../../../../utils/formatTemplateText';

function FormMultiSelect({
  helperText,
  internalId,
  label,
  required,
  fontColor,
  options,
  answers,
  onAnswerChange,
  variables,
  disabled,
  hasError,
}) {
  const [values, setValues] = useState([]);

  useEffect(() => {
    setValues(answers[internalId].value.split(',').filter(v => v !== ''));
  }, [answers[internalId].value]);

  return (
    <fieldset className={`input-container${hasError ? ' error' : ''}`}>
      {!label ? null :
        <legend
          style={{ color: fontColor }}
          htmlFor={internalId}
        >
          {formatTemplateText(label, variables)}{!required ? '' : ' *'}
        </legend>
      }
      {options.map((o, i) => {
        return (
          <div key={`${internalId}-option-${i}`} className="checkbox-row">
            <input
              type="checkbox"
              id={o}
              checked={values.includes(o)}
              onChange={(e) => {
                const updatedValues = [ ...values ];

                if (e.target.checked) {
                  updatedValues.push(o);
                } else {
                  const valueIndex = updatedValues.findIndex((v) => v === o);

                  updatedValues.splice(valueIndex, 1);
                }

                onAnswerChange(internalId, updatedValues.join(','));
              }}
              disabled={disabled || false}
            />
            <label
              style={{ color: fontColor }}
              htmlFor={o}
            >
              {o}
            </label>
          </div>
        );
      })}
      {!helperText ? null : <small style={{ color: fontColor }}>{formatTemplateText(helperText, variables)}</small>}
    </fieldset>
  );
}

export default FormMultiSelect;
